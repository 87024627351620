$primary-color: #40beee;
$light-gray-color: #f5f8fa;
$white-color: #ffffff;
$black-color: #14141a;

.custom-table {
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #f0f7ff;
    border-color: none !important;
  }
  .ant-table-thead > tr > th {
    background-color: transparent;
    color: #4f4f4f !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .ant-table-thead > tr > td {
    background-color: transparent;
  }
  .ant-table-tbody td {
    color: $black-color !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 10px solid #ffffff;
  }
  .ant-table-tbody > tr {
    background-color: #fbfbfb;
  }
  table tr td.ant-table-selection-column,
  table tr th.ant-table-selection-column {
    padding-right: 8px;
    padding-left: 8px;
    text-align: start !important;
    width: 0px !important;
  }
  .ant-avatar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ededed;
    width: 25px !important;
    height: 25px !important;
    line-height: 36px;
    border-radius: 50%;
  }
}

.fileUploader {
  margin-top: 36px;
  margin-bottom: 18px;
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 4px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    color: #000000 !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
    font-weight: 500 !important;
  }
  .ant-upload.ant-upload-drag {
    border: 2px dashed #e6ebf1;
    border-radius: 0.625rem;
    padding: 0 16px;
    background: 0 0;
    min-height: 160px !important;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 30px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 12px !important;
  }
}
.addGeneData {
  // min-height: 36rem !important;
  .ant-form-item-label > label {
    color: #4d4d4d !important;
    font-size: 12px !important;
  }

  .ant-form {
    min-height: 36rem !important;
  }

  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 8.5px 11px;
    color: #999999 !important;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e6ebf1;
    border-radius: 6px !important;
    transition: all 0.3s;
    display: -webkit-inline-flex;
    display: inline-flex;
  }

  .ant-btn-primary {
    color: #fff;
    background: $primary-color;
    border-color: $primary-color;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    width: -webkit-fill-available !important;
    border-radius: 6px !important;
  }
}
.dataFiles {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    padding-left: calc(3rem - 8px);
    background-color: #fff;
    border: 1px solid #e6ebf1;
    border-radius: 6px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-card {
    margin: 0px !important;
  }
}
.geneKits {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    padding-left: calc(3rem - 8px);

    border: 1px solid #e6ebf1;
    border-radius: 6px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-card {
    margin: 0px !important;
  }
  .ant-tabs .ant-tabs-tab {
    font-size: 16px !important;
  }
  .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }
}
.geneticModal {
  .notification {
    background-color: #e9f6fc !important;
    padding: 10px;
    border-radius: 6px !important;
  }
  .ant-checkbox-group,
  .ant-checkbox-wrapper {
    margin-left: 8px !important;
  }
  .ant-checkbox {
    overflow: inherit !important;
    // align-self: auto !important;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    padding: 0px !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
  .geneKitInstructions {
    background-color: $primary-color !important;
    .ant-typography {
      color: white !important;
    }

    .typoText {
      font-size: 12px !important;
    }
  }
}
.ant-pagination-item,
.ant-pagination-total-text .ant-pagination-item {
  // border-radius: 50% !important;
  line-height: 30px !important;
}
:where(.css-dev-only-do-not-override-dfjnss).ant-pagination
  .ant-pagination-item-active {
  font-weight: 600 !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
  border-color: $primary-color !important;
}
:where(.css-dev-only-do-not-override-dfjnss).ant-pagination
  .ant-pagination-item-active
  a {
  color: $white-color !important;
  border-color: $primary-color !important;
}
.ant-tabs .ant-tabs-ink-bar {
  background: $primary-color !important;
}
.ant-pagination-item-active a {
  line-height: 26px !important; /* Adjust the line-height as per your requirement */
}
:where(.css-dev-only-do-not-override-dfjnss).ant-pagination
  .ant-pagination-item {
  line-height: 26px !important;
  display: inline-block;
  min-width: 28px !important;
  height: 28px !important;
  margin-inline-end: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 26px !important;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
:where(.css-dev-only-do-not-override-dfjnss).ant-pagination {
  .ant-pagination-prev {
    display: inline-block;
    min-width: 28px !important;
    height: 28px !important;
    color: rgba(0, 0, 0, 0.88);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 26px !important;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 50px !important;
    cursor: pointer;
    transition: all 0.2s;
    background-color: $white-color !important;
  }
  .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    // border: 1px solid gray !important;
    background-color: white !important;
    // border: 1px solid transparent;
    // border-radius: 50px !important;
    outline: none;
    transition: border 0.2s;
  }
  .ant-pagination-next {
    display: inline-block;
    min-width: 28px !important;
    height: 28px !important;
    color: rgba(0, 0, 0, 0.88);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 26px !important;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 50px !important;
    cursor: pointer;
    transition: all 0.2s;
    background-color: $white-color !important;
  }
}
.ant-tabs-tab {
  font-size: 16px !important;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 1.7px !important;
}

.table-container {
  overflow-x: auto;
  min-width: 100%;
}

.messages-header hr {
  height: 1px;
  background-color: #dbdbdb;
  border: none;
}

.border-bottom {
  border-bottom: 1px solid #dbdbdb;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  margin-top: 0 !important;
  height: 27px !important;
}

.hide-upload-icon .ant-upload {
  display: none !important;
}

.hide-upload-icon .ant-upload-list {
  display: block !important;
}
.ant-upload-list {
  display: none;
}
