$primary-color: #40beee;
$light-gray-color: #f5f8fa;
$white-color: #ffffff;
$black-color: #000000;

body {
  font-family: "Roboto", sans-serif !important;
  background-color: #f1f6f6 !important;
  margin: 0;
  * {
    font-family: "Roboto", sans-serif !important;
  }
}

html {
  --dark-gray-color: rgba(0, 0, 0, 0.35);
  font-family: "Roboto", sans-serif;
}

::placeholder {
  color: #d4d9e0 !important;
}
a {
  font-size: 13.6px !important;
  @media (min-width: 768px) {
    .sidebar.toggled a {
      font-size: 12px !important;
    }
  }
}

.loaderDiv {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 80%);
  z-index: 99999;
  cursor: pointer;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: #4a5fb2;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .spinner-body {
    width: 5rem;
    height: 5rem;
    color: #4a5fb2;
  }
}
.loaderDiv.for-request {
  background-color: rgb(0 0 0 / 32%);
}
.cursor-pointer:hover {
  cursor: pointer;
}

.sider-not-collapsed {
  margin-left: 260px;
}
.sider-collapsed {
  margin-left: 0;
}
.ant-layout {
  .ant-layout-sider-children {
    .ant-menu {
      background-color: $white-color !important;
      padding: 0px 25px 0px 0px;
      font-size: 18px;
      font-weight: 400;
      .ant-menu-item:active {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        background-color: rgba(0, 0, 0, 0.06);
      }
      .ant-menu-item:hover {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        .sider-icon.ant-menu-item-icon {
          color: #40beee;
        }
      }
      .ant-menu-item {
        a {
          font-size: 17px !important;
        }
      }
    }
    .sider-icon.ant-menu-item-icon {
      color: rgba(0, 0, 0, 0.6);
    }

    .ant-menu-item-selected,
    .ant-menu-item-selected:hover {
      .sider-icon {
        color: #fff !important;
      }
      background-color: $primary-color;
      color: $white-color !important;
      a {
        color: $white-color !important;
      }
    }
    background-color: $white-color;
    border-radius: 0;
    align-items: center;
  }
  .ant-layout-header {
    background-color: $white-color;
    line-height: 6px;
    justify-content: space-between;
    padding: 1.5rem 2.5rem !important;
    height: 90px;
  }
  .ant-layout-sider {
    background: linear-gradient(#ffffff00, #ffffff);
    // max-width: 260px !important;
    // min-width: 260px !important;
    // width: 260px !important;
  }
  height: 100vh;
}

.ant-menu {
  .ant-menu-item {
    color: $white-color;
  }
}

.ant-layout-content {
  margin: 110px 2.5rem 10px 2.5rem !important;
  min-height: unset !important;
  padding-bottom: 0;
}

.sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 70px;
  bottom: 0;
  z-index: 10 !important;
}

.user-avatar {
  min-height: 40px;
  min-width: 40px;
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid $primary-color;
}

.user-avatar-2 {
  height: 65px;
  width: 65px;
  object-fit: cover;
  border-radius: 50%;
}

.font-sm {
  font-size: 12px;
}

.font-md {
  font-size: 16px;
}

.font-lg {
  font-size: 22px;
}

.text-black {
  color: $black-color;
}

.font-Roboto {
  font-family: "Roboto", sans-serif;
}

.text-light-gray {
  color: #66666629 !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
  border-radius: 25px;
}

.ant-layout-footer {
  padding: 6px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 12px;
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

@media (max-width: 768px) {
  .ant-layout {
    position: relative;
    .ant-layout-sider {
      z-index: 2;
      height: 100vh;
    }
  }
}

.visibility-none {
  visibility: hidden;
}
.sidebar-trigger-collapsed {
  display: block;
}

@media (min-width: 768px) {
  .sidebar-trigger-collapsed {
    display: none !important;
  }
}

.close-icon {
  position: absolute;
  top: 25px;
  right: 25px;
}

.auth-parent {
  height: calc(100vh - 30px);
  .login-img-overlay {
    background-color: #40beee;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 7px;
    right: 7px;
    width: calc(100% - 14px);
    height: 100%;
  }
  .login-form {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
  .login-img {
    object-fit: cover;
    width: 100%;
    border-radius: 0.35rem;
    overflow: hidden;
    height: calc(100vh - 530px);
    min-height: 100%;
  }
  .login-logo-img {
    width: 140px;
    height: 140px;
    object-fit: cover;
  }
}

.labels {
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.ant-input-affix-wrapper:hover .ant-input-prefix {
  color: $primary-color;
}

.ant-input-affix-wrapper .ant-input-prefix {
  color: #999999;
}

.login-card {
  box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.1);
}

.login-inner-card {
  position: absolute !important;
  bottom: 10px;
  left: 15px !important;
  right: 15px !important;
  background: none !important;
  // width: 88% !important;
  .ant-card-body {
    padding: 1.3rem 1rem;
    border-radius: 0.35rem !important;
    background: rgb(255 255 255 / 73%);
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(5px);
  }
}

.register-genetic-kit-parent {
  .ant-card-body {
    padding: 0.8rem;
  }
}
// Generic classes

.bg-blue {
  background-color: #d2edf9 !important;
}
.bg-gray {
  background-color: #f2f2f2 !important;
}
.bg-light-blue {
  background-color: #e9f6fc !important;
}
.ant-btn-submit {
  background-color: $primary-color !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
}

.btn-outline {
  border: 2px solid $primary-color !important;
  background: transparent !important;
  height: 38px !important;
  padding: 8.5px 0.6rem !important;
}

.ant-checkbox .ant-checkbox-inner {
  border: 1px solid #cccccc !important;
  width: 18px !important;
  height: 18px !important;
  border-radius: 3px !important;
}
.fs-xs {
  font-size: 0.8rem;
}

.fs-xs-sm {
  font-size: 0.9rem;
}

.fs-sm {
  font-size: 1rem;
}

.fs-md {
  font-size: 1.15rem;
}

.fs-lg {
  font-size: 1.5rem !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-heavy {
  font-weight: 600 !important;
}

.fw-black {
  font-weight: 700 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fs-warning {
  font-size: 0.95rem;
}

.react-tel-input .form-control {
  height: 39px !important;
  border-radius: 0.35rem !important;
}
.react-tel-input .selected-flag {
  border-radius: 0.35rem;
}

.align-items-vertically-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-chat {
  height: 20em;
}

.chat-parent {
  .chat-details {
    width: 300px;
    background-color: #fff;
    position: fixed;
    top: 70px;
    right: 0;
    height: 100vh;
    z-index: 1;
    // border-left: 1px solid #1aaa56;
    box-shadow: 1px 4px 3px 1px #d3d3d3;
  }

  .newchat-details {
    width: 300px;
    background-color: #fff;
    position: fixed;
    top: 90px;
    right: 0;
    height: 100vh;
    z-index: 1;
  }
  // .expanded-inbox{
  //   width: calc(100% - 260px);
  // }
  .status-btn {
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.6);

    border-radius: 0.625rem;
  }
  .notification-badge {
    background-color: #1aaa56;
    border-radius: 0.45rem;
    padding: 1px 7px;
    color: #fff;
  }
  .sender-avatar {
    height: 55px;
    width: 55px;
    object-fit: cover;
    border-radius: 50%;
    padding: 2px;
    border: 2px solid #ccc;
  }

  .sender-avatar.active {
    border: 2px solid #fff;
  }

  .chat-list {
    height: calc(100vh - 270px);
    overflow-y: auto;
    // overflow-x: hidden;
    padding-bottom: 100px;
  }
  .single-chat {
    padding-left: 16px;
  }
  .single-chat.active {
    background-color: $primary-color;
    color: $white-color;
  }
  @media (max-width: 985px) {
    .chat-details {
      width: 100%;
    }
    .newchat-details {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .newchat-details,
    .chat-details {
      width: 100% !important;
    }
  }
}

@media (max-width: 985px) {
  .ant-layout-content {
    margin: 110px 0 10px 1rem !important;
  }
  .livechat-wrapper,
  .dashboard-wrapper {
    padding-right: 1rem !important;
  }
  .ant-layout {
    .ant-layout-header {
      padding: 1rem !important;
    }
  }
  .chat-card {
    .messages-header,
    .send-message-wrapper,
    .messages-container {
      padding-right: 1rem !important;
    }
  }
}
.mobile-chat-vew {
  .newchat-details,
  .chat-details {
    // display: none;
  }
  .chat-card {
    width: 100%;
  }
}

.icon-circle-bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none !important;
}

.messages-header {
  padding-right: 2.5rem;
}

.business-group-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.business-group-item:hover {
  cursor: pointer;
  background-color: #40beee17;
  border-radius: 0.35rem;
}

.business-group-item.active {
  background-color: #40beee17;
  border-radius: 0.35rem;
}

.chat-modal {
  .ant-modal-footer {
    margin-top: 0px !important;
  }
  .ant-modal-header {
    margin-bottom: 0px !important;
  }
  .ant-modal-body {
    background: #f1f6f6;
  }
}

.chat-card {
  width: calc(100% - 262px);
  position: relative;
  height: calc(100vh - 115px);

  .messages-container {
    height: calc(100vh - 270px);
    overflow: auto;
    padding-right: 2.5rem;

    ul li:first-child .ant-card {
      border: 1px solid #87cb60 !important;
    }

    .message-sent {
      display: flex;
      justify-content: end;
      .ant-card {
        background-color: #d6f1efd1;
        width: fit-content;
        margin-left: auto;
      }
    }
    .message-received {
      display: flex;
      justify-content: flex-start;
    }
    .message-sent {
      .ant-card {
        border-top-left-radius: 0;
        margin-bottom: 0 !important;
        border: none !important;
        // max-width: 40%;
        .ant-card-body {
          padding: 5px 5px 0px 5px;
          display: flex;
          width: fit-content;
          span {
            div {
              font-size: 10px !important;
              margin-top: 15px;
            }
          }
        }
      }
    }
    li,
    div {
      list-style: none;
      .ant-card {
        border-top-left-radius: 0;
        margin-bottom: 0 !important;
        border: none !important;
        // max-width: 40%;
        .ant-card-body {
          padding: 10px;
          display: flex;
          width: fit-content;
        }
      }
    }
  }
  .send-message-wrapper {
    position: absolute !important;
    bottom: 20px !important;
    left: 0 !important;
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 2.5rem;
    .ant-input {
      padding-left: 0.625rem;
    }
    .ant-input-suffix {
      padding-right: 0.625rem;
    }
    .send-icon-bg {
      background-color: #40beee;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 11px;
    }
    .send-message {
      display: inline-block;
      // width: 100% !important;
      min-width: 0;
      height: 50px !important;
      padding: 8.5px 11px;
      color: #455560;
      font-size: 14px;
      line-height: 1.5;
      background-color: #fff;
      background-image: none;
      border: none !important;
      border-radius: 50px !important;
      transition: all 0.3s;
      display: inline-flex;

      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09) !important;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09) !important;
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09) !important;
    }
    .all-messages-section {
      height: calc(100vh - 200px) !important;
    }
  }
}

.search-input
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #cfcfcf;
  border-radius: 0.625rem;
}

.details-icon {
  background-color: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.6);
}

.text-blue {
  color: #40beee !important;
}

.search-box .btn-search:focus,
.search-box .btn-search:hover {
  background: #fff !important;
  color: #000 !important;
  border: none !important;
  box-shadow: none !important;
}

.search-box {
  width: fit-content;
  height: inherit;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  word-spacing: normal;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  display: none !important;
}

.ant-input-search .ant-input:hover {
  border-color: #fff;
}

.input-search {
  height: 30px;
  width: 50px;
  border-style: none;
  padding: 10px 18px;
  font-size: 13px;
  letter-spacing: 1px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color: black;
}

.input-search::placeholder {
  color: black !important;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 100;
}

.btn-search {
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  /* right: 10px; */
  color: #000000;
  pointer-events: painted;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-search:focus ~ .input-search {
  width: 260px;
  background-color: #fff;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
  color: black;
  border-radius: 20px;
}

.input-search:focus {
  width: 250px;
  background-color: #fff;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
  border-radius: 20px;
}

.input-search::placeholder {
  letter-spacing: 1px;
  padding: 180px 0px;
}

.dashboard-wrapper {
  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    padding: 16px !important;
    margin: 0;
    border-radius: 0 0 0.625rem 0.625rem;
    background-color: #fff;
  }
  .ant-table-cell-scrollbar {
    box-shadow: none !important;
    background-color: #40beee !important;
  }

  .ant-spin-container {
    // background: #fff;
    padding-bottom: 0.5rem;
    border-radius: 0.625rem;
  }
  .ant-table {
    .ant-table-head {
      border: none !important;
    }
    .ant-table-header,
    table {
      border-radius: 0 !important;
    }
    background: transparent !important;
    .ant-table-body {
      margin-top: 1.5rem !important;
      background: white !important;
      border-radius: 0.625rem !important;
    }
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0 !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    border: 1px solid #40beee !important;
    border-radius: 0.625rem;
  }
  .ant-table-container {
    .ant-table-header {
      th {
        background-color: #40beee;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border: none !important;
      }
    }
    .ant-table-body {
      td {
        // font-weight: 600;
        border: none !important;
      }
    }
  }
}

.dashboard-wrapper
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-dfjnss).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0 !important;
  height: 0 !important;
}

.dashboard-wrapper {
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border: 0 !important;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0 !important;
  }
}

.search-bar {
  width: 50px;
  transition: width 0.3s ease;
  position: relative;
}

.search-bar.expanded {
  width: 300px;
}
.search-bar input:focus,
.search-bar input:hover,
.search-bar input {
  width: 100%;
  padding: 6px 12px;
  border: none;
  display: none;
  border-radius: 50px;
}

.search-bar.expanded input {
  display: block;
}

.collapse-search {
  position: absolute;
  right: 0;
  z-index: 1;
}

.mw-70 {
  max-width: 70% !important;
}

.add-group-modal {
  .ant-modal-header {
    border: 0 !important;
  }
  .add-icon-bg {
    height: 40px;
    width: 40px;
    display: flex !important;
    align-items: center;
    background-color: #40beee1a;
    justify-content: center;
    border-radius: 5.5px;
  }
  .add-icon {
    color: #40beee;
  }
  .ant-select-selector {
    border: 1px solid #cfcfcf;
    border-radius: 0.325rem !important;
  }
  .ant-input {
    border: 1px solid #cfcfcf;
    border-radius: 0.35rem !important;
  }
  .ant-modal-header {
    padding: 8px 24px;
  }
}

.add-call-modal {
  .call-user-avatar {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
  }
  .ant-card {
    border: none;
    .call-end-icon-bg {
      background-color: rgb(255, 48, 48);
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    .call-accept-icon-bg {
      background-color: rgb(0, 219, 58);
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }
}

.send-message-inner-wrapper {
  position: relative;
  .upload-icon {
    position: absolute;
    right: 30px;
    top: 13px;
  }
}

.filter-select {
  width: 166px;
}

.group-data-table {
  thead {
    background-color: #40beee05 !important;
    color: #40beee;
    // height: 35px;
    tr {
      border-bottom: 2px solid #fff;
      th {
        padding: 7px;
      }
    }
  }
  tbody {
    background-color: #40beee05 !important;
    color: #000;
    // height: 35px;
    tr {
      border-bottom: 1px solid #fff;
      border-top: 1px solid #fff;
      td {
        padding: 7px;
      }
    }
  }
}

.parent-div {
  display: flex;
  gap: 0.625rem;
}

.item {
  flex: 1;
}

.account-settings-wrapper {
  .card-title {
    color: #40beee;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .image-rounded {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  .ant-btn-secondary {
    background-color: #f5f5f5;
    color: #40beee !important;
  }
  .ant-btn-outline:hover,
  .ant-btn-outline:focus,
  .ant-btn-outline {
    color: #40beee !important;
    background: #fff !important;
    border: 1px solid #40beee !important;
  }
}

.backdrop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
  z-index: 9;
}

.group-title {
  background-color: #40beee05;
  color: #40beee;
}
.livechat-wrapper {
  .live-chat-scroll {
    max-height: calc(100vh - 500px);
    // overflow-y: scroll;
  }
  li {
    list-style: none;
    background-color: #40beee05;
    color: #40beee;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    padding: 8px 14px;
  }
  .ant-radio {
    align-self: self-start;
    top: 3px;
  }
}

* {
  font-family: "Roboto", sans-serif !important;
}
.ant-layout {
  .ant-layout-header {
    // background-color: #40beee;
    line-height: 6px;
    justify-content: space-between;
    padding: 0 2.5rem !important;
    height: 70px;
    display: flex;
    align-items: center;
  }
}
.page-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.user-avatar {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: none;
  padding: 4px;
  border: 1px solid #ccc;
}

.support-icon-bg {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #40beee;
  display: flex;
  align-items: center;
  justify-content: center;
  .support-icon {
    filter: invert(57%) sepia(55%) saturate(456%) hue-rotate(128deg)
      brightness(94%) contrast(91%);
    height: 20px;
    width: 20px;
    object-fit: cover;
  }
}

.account-inner-wrapper {
  position: relative;
  height: calc(100vh);
  .chat-icon-parent::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(-30deg);
    left: -22px;
    bottom: -4px;
    border: 10px solid transparent;
    border-right: 32px solid #40beee;
    z-index: 2;
  }
  .chat-icon-parent {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60px;
    // width: 130px;
    border-radius: 50px;
    background-color: #40beee;
    display: flex;
    align-items: center;
    padding: 8px;
    .chat-inner {
      color: #fff;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1;
    }
    .chat-icon-bg {
      background-color: #fff !important;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .chat-icon {
        width: 35px;
        height: 35px;
        object-fit: cover;
        filter: invert(57%) sepia(55%) saturate(456%) hue-rotate(128deg)
          brightness(94%) contrast(91%);
      }
    }
  }
}

.uml-logo {
  width: 30px;
  object-fit: cover;
  margin-right: 1rem;
}

.tickets-wrapper {
  .ant-input {
    border-radius: 0.625rem;
  }
  .ant-select-selector {
    background: #fff !important;
  }
}

.unselected-btn {
  background-color: rgba(56, 178, 172, 0.0196078431);
  color: #40beee;
  padding: 5px 10px;
  border-radius: 5px;
}

.not-found-container {
  text-align: center;
  margin-top: 100px;
  width: 100%;
  height: 100%;
}

.not-found-container h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.not-found-container p {
  font-size: 1.2rem;
}

.group-wrapper .ant-table-thead th {
  background-color: #40beee !important;
  color: #fff !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  border: none !important;
}

/* Custom.css */
@media (max-width: 767.98px) {
  .hide-on-mobile {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .show-on-mobile {
    display: block !important;
  }
}
