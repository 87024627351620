/* body,
html {
    background-color: pink;
    height: 100%;
    font-family: Verdana, Geneva, sans-serif
} */

/* .connectionStatus {
    padding-top: 20px;
    margin-left: 5%;
    font-size: 1.5em;
} */

/* .subscriber {
    margin-left: 10%;
} */

/* .publisher {
    float: right;
    margin-right: 10%;
} */

.OTPublisherContainer {
  /* width: 80vh !important;
    height: 80vh !important; */
  width: 100%;
  height: 100vh;
}

#error {
  color: red;
}

#history .mine {
  color: red;
  /* text-align: right;
    margin-right: 10px; */
}

#history .theirs {
  color: blue;
}

.calling-section,
.video-call > div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 0.5rem;
  width: 100%;
  height: calc(100vh - 4px);
  padding: 0.25em;
}
.OT_widget-container {
  border-radius: 5% !important;
  background: linear-gradient(60.08deg, #756e6e 0%, #1a1a1a 100%) !important;
}
/* .calling-section,
.video-call>div:nth-child(2)>div {
    height: 100% !important;
} */
.video-call > div > div:last-child {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between;
}

.video-call > div > div:last-child > div {
  height: 30%;
  width: 49%;
  margin-bottom: 10px;
}

.button-div {
  height: fit-content !important;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 9999;
  /* background: #eff4f4; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.button-div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.user-calling-img {
  width: 60px;
  height: 60px;
}

.OT_edge-bar-item.OT_mute.OT_mode-auto {
  display: none !important;
}

.OT_bar.OT_edge-bar-item.OT_mode-auto {
  display: none !important;
}
/* .video-call > div > div:last-child {
  display: none !important;
} */
.button-div > .subscriber-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

/* .video-call>div:nth-child(2) {
    height: 100%;
} */

/* #root>div.private-layout>section>section>main>div.layout-child>div>div>div,
#root>div.private-layout>section>section>main>div.layout-child>div>div>div>div {
    height: 100% !important;
} */

.calling-section > div,
.publisher,
.subscriber {
  position: relative;
  /* background-color: #e8e8e8; */
  border-radius: 0.15rem;
  /* height: calc(100dvh - 1rem); */
  /* z-index: 9; */
  height: 100% !important;
}

/* .publisher>div {
    height: 100% !important;
} */

.publisher {
  display: flex;
  flex-direction: column;
}

.publisher2 {
  flex: 1 !important;
}

.subscriber {
  display: flex;
  flex-direction: column;
}

.subscriber-2 {
  flex: 1;
}

.OTPublisherContainer,
.OTSubscriberContainer {
  width: 100% !important;
  height: 100% !important;
}

.calling-section > div > video {
  object-fit: cover;
}

.controls {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  width: 60%;
  height: 3rem;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #c8c8c88a;
  gap: 2rem;
}

.accept-call-btn {
  background-color: rgb(0, 219, 58);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.call-end-btn {
  background-color: rgb(255, 48, 48);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.call-modal {
  & .caller-name {
    text-align: center;
  }
}

@media (max-width: 1030px) {
  .video-call > div > div:last-child {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
  }
  .subscriber {
    z-index: 9;
  }
}
